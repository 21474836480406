import React, { useEffect, useState } from 'react';
import { Grid } from 'tabler-react';
import { Link } from 'react-router-dom';

import ItemsRotator from '../Common/ItemsRotator';
import './Home.scss';

import bannerIMG1 from '../assets/banners/banner-1.jpg';
import bannerIMG2 from '../assets/banners/banner-2.jpg';
import bannerIMG3 from '../assets/banners/slide-1.jpg';
import { useStore } from '../utils/Store';
import Axios from 'axios';
import api from '../utils/Api';

const Home = {
    Index: () => {
        return (<>
            <Home.Banner />
            <div className="line py-5">
                <div className="lite"></div>
            </div>
            <Home.Products />
        </>);
    },
    Banner: () => {
        const view = (
            <div className="home banner-wrapper">
                <Grid.Row gutters="xs">
                    <Grid.Col md={5} width={12}>
                        <Grid.Row gutters="xs">
                            <Grid.Col width={12}><img src={bannerIMG1} alt="banner 1" /></Grid.Col>
                            <Grid.Col width={12}><img src={bannerIMG2} alt="banner 2" /></Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col md={7} width={12}>
                        <img src={bannerIMG3} alt="banner 3" />
                    </Grid.Col>
                </Grid.Row>
            </div>);

        return view;
    },
    Products: () => {
        const [store] = useStore();
        const [homeData, setHomeData] = useState({ success: false });
        useEffect(() => {
            Axios.get(api.HOME_DATA)
                .then(res => {
                    if (res.data.success) {
                        setHomeData({ success: true, ...res.data.data });
                    }
                }, err => {

                });
        }, [homeData.success]);

        const sideMenu = (
            <div className="side-menu">
                <h3><span>S</span>hop grocery</h3>
                <div className="menu-highlight">
                    <ul>
                        {
                            store.categoryList.map((item, key) => <li key={item.id}><Link to={`/shop/category/${item.id}`}><div>{item.name}</div></Link></li>)
                        }
                    </ul>
                    <div className="line"><div className="thick"></div></div>
                </div>
            </div>
        );

        return (<div className="home">
            <Grid.Row gutters="md">
                <Grid.Col width={12} md={3}>
                    {sideMenu}
                </Grid.Col>
                <Grid.Col width={12} md={9}>
                    {
                        homeData.top_selling ? <ItemsRotator items={homeData.top_selling} title='Top Selling Products' /> : ''
                    }

                </Grid.Col>
            </Grid.Row>
        </div>)
    }
}

export default Home;