import React, { useEffect, useState } from 'react';
import { useStore } from '../utils/Store';
import { useHistory } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Stamp, Card, Grid, Button } from 'tabler-react';
import api from '../utils/Api';
import Axios from 'axios';

const Pay = {
    Index: () => {
        const history = useHistory();
        const [store, setStore] = useStore();
        const [cartData, setCart] = useState({ success: false, busy: false });

        useEffect(() => {
            setStore({ ...store, cartStep: 3, deliveryMethod: 'normal', paymentMode: 'cod' });

            if (store.cart.length > 0) {
                setCart({ success: false, busy: true });
                Axios.post(api.CART_GUEST, { items: store.cart })
                    .then(res => {
                        if (res.data.success) {
                            setCart({ ...cartData, ...res.data.data, busy: false });
                        } else {
                            setCart({ ...cartData, busy: false });
                        }
                    }, err => {

                    });
            }

        }, []);

        function confirm() {
            history.replace({ pathname: '/cart/success' });
        }
        const view = cartData.summary ? (
            <div className="payment-container">
                <Grid.Row gutters="lg">
                    <Grid.Col width="12" lg="9" className="pr-lg-5">
                        <Pay.DeliveryMethod />
                        <Pay.PaymentMethod />
                    </Grid.Col>
                    <Grid.Col width="12" lg="3">
                        <h3><span>R</span>eview your order</h3>
                        <div className="mb-3 cart-common order-summary">
                            <div className="total">Total: <span>₹{cartData.summary.without_offer}</span></div>
                            <div className="discount">Discount:
                            {
                                    cartData.summary.without_offer != cartData.summary.sum ? <span> -₹{cartData.summary.without_offer - cartData.summary.sum}</span> : <span> ₹0</span>
                                }

                            </div>
                            <div className="total">Shipping: <span>₹0</span></div>
                            <div className="line"><div className="lite"></div></div>
                            <div className="total-final">Order Total: <span>₹{cartData.summary.sum}</span></div>
                        </div>
                        <Button block color="success" className="anim" onClick={confirm}>Place Order<Icon.Check /></Button>
                    </Grid.Col>
                </Grid.Row>
            </div>
        ) : '';

        return view;
    },
    DeliveryMethod: () => {
        const [store, setStore] = useStore();
        const allMethods = [
            {
                id: 'normal',
                name: 'Normal',
                description: 'Arrive in 5-7 hours',
                icon: () => <Icon.CheckCircle width="40px" />,
                available: true
            },
            {
                id: 'instant',
                name: 'Instant',
                description: 'Arrive in 1-2 hours',
                icon: () => <Icon.FastForward width="40px" />,
                available: false
            },
            {
                id: 'scheduled',
                name: 'Scheduled',
                description: 'Delivery by your choice',
                icon: () => <Icon.Clock width="40px" />,
                available: false
            }
        ];

        function setDelivery(item) {
            if (!item.available) {
                return;
            }
            setStore({ ...store, deliveryMethod: item.id })
        }

        return (
            <div className="delivery-methods pb-5">
                <label><h3><span>C</span>hoose your delivery method</h3></label>
                <Grid.Row>

                    {
                        allMethods.map((item, key) => (
                            <Grid.Col width="12" sm="5" md="4" lg="4" key={key}>
                                <Card onClick={() => setDelivery(item)} className={'mb-3 ' + (item.id === store.deliveryMethod ? 'active' : '') + (!item.available ? 'inactive' : '')}>
                                    <Card.Body>
                                        <div className="icon">
                                            <item.icon />
                                        </div>
                                        <label className="title">
                                            {item.name}
                                        </label>
                                        <label className="subtitle">{item.description}</label>
                                        {
                                            !item.available ? <label className="unavailable">Currently not available</label> : ''
                                        }
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                        ))
                    }
                </Grid.Row>

            </div >
        );

    },
    PaymentMethod: () => {
        const [store, setStore] = useStore();

        const allMethods = [
            {
                id: 'cod',
                name: 'Cash on Delivery',
                available: true,
                icon: () => <Icon.ShoppingBag width="15px" />
            },
            {
                id: 'card',
                name: 'Debit/Credit Card',
                available: false,
                icon: () => <Icon.CreditCard width="15px" />
            },
            {
                id: 'upi',
                name: 'UPI',
                available: false,
                icon: () => <Icon.Tablet width="15px" />
            }

        ];

        function paymentMode(item) {
            if (!item.available) {
                return;
            }
            setStore({ ...store, paymentMode: item.id })
        }

        return (
            <div className="payment-methods pb-5">
                <label><h3><span>C</span>hoose your payment method</h3></label>
                <Grid.Row>

                    {
                        allMethods.map((item, key) => (
                            <Grid.Col width="12" sm="5" md="4" lg="4" key={key}>
                                <Card className={'mb-3 ' + (!item.available ? 'inactive' : '') + (store.paymentMode === item.id ? ' active' : '')} onClick={() => paymentMode(item)}>
                                    <Card.Body>
                                        <Stamp size="xs" className="mr-1">
                                            <item.icon />
                                        </Stamp> <div className="deets">
                                            <label>{item.name}</label>
                                            {
                                                !item.available ? <label className="sub">Currently not available</label> : ''
                                            }

                                        </div>
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                        ))
                    }
                </Grid.Row>

            </div >
        );
    }
};

export default Pay;