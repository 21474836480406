import React, { useEffect, useState } from 'react';
import { Grid, Card, Badge, Button, Dimmer } from 'tabler-react';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import api from '../utils/Api';

import './Cart.scss';
import emptyCartImg from '../assets/placeholders/empty-cart.svg';
import Loader from '../Common/Loader';

const Cart = {
    Index: () => {
        const [store, setStore] = useStore();
        const [cartData, setCart] = useState({ success: false, busy: false, items: [] });

        useEffect(() => {
            if (store.cart.length > 0) {
                setCart({ success: false, busy: true, items: [] });
                Axios.post(api.CART_GUEST, { items: store.cart })
                    .then(res => {
                        if (res.data.success) {
                            setCart({ ...cartData, ...res.data.data, busy: false });
                        } else {
                            setCart({ ...cartData, busy: false });
                        }
                    }, err => {

                    });
            } else {
                setCart({ success: false, busy: false, items: [] });
            }
        }, [store.cart]);

        useEffect(() => {
            setStore({ ...store, cartStep: 1 });
        }, []);

        const placeHolder = cartData.busy ? <Loader /> : (<div className="content-placeholder">
            <img src={emptyCartImg} alt="no items found" />

            <h2 className="py-4">Your cart is empty!</h2>

            <div className="pb-3">
                <Link to="/"><Button outline color="success">Browse products</Button></Link>
            </div>
        </div>);

        const view = (
            <div className="cart-container">
                {
                    cartData.items.length > 0 ? (<>
                        <Grid.Row className="justify-content-md-between">
                            <Grid.Col width="12" md="7" lg="6" xl="5" className="mb-5">
                                <h3><span>{cartData.items.length}</span> items</h3>
                                <Cart.Items items={cartData.items} />
                            </Grid.Col>
                            <Grid.Col width="12" md="4">
                                <Cart.Summary summary={cartData.summary} />
                            </Grid.Col>
                        </Grid.Row>
                    </>) : placeHolder
                }
            </div>
        )
        return view;
    },
    Items: (props) => {
        const [store, setStore] = useStore();

        function checkItemInCart(id, filter) {
            const found = store.cart.filter(item => item.id === id && item.filter === filter);
            return found.length;
        }

        function addOne(id, filterData) {
            let filterID = null;
            if (filterData) {
                filterID = filterData.id;
            }
            if (checkItemInCart(id, filterID)) {
                const cartData = store.cart.map(item => {
                    if (item.id === id && item.filter === filterID) { item.qty++; }
                    return item;
                });
                setStore({ ...store, cart: cartData });
            } else {
                store.cart.push({ id, qty: 1, filter: filterID });
                setStore({ ...store });
            }

        }
        function decOne(id, filterData) {
            let filterID = null;
            if (filterData) {
                filterID = filterData.id;
            }
            if (checkItemInCart(id, filterID)) {
                const cartData = store.cart.reduce((result, item) => {
                    if (item.id === id && item.filter === filterID) { item.qty--; }
                    if (item.qty > 0) {
                        result.push(item);
                    }
                    return result;
                }, []);
                setStore({ ...store, cart: cartData });
            }
        }

        const list = props.items.map(item => (
            <Grid.Row key={item.id}>
                <Grid.Col className="mb-3">
                    <Card>
                        <Card.Body>
                            <div className="thumb">
                                <Link to={'/shop/product/' + item.id}><img src={item.thumb} alt={item.name} /></Link>
                            </div>
                            <div className="details">
                                <div className="item-price">
                                    <div className="price">
                                        {
                                            item.offer > 0 ?
                                                (<><label>₹{item.price}<span>₹{item.price_real}</span></label>
                                                    <Badge color="success">{item.offer} OFF</Badge></>)
                                                : (<label>₹{item.price}</label>)
                                        }
                                    </div>
                                    <div className="p-sum">₹{item.price_sum}</div>
                                </div>
                                <div className="item-name">{item.name}</div>
                                <div className="item-misc">
                                    <div className="wt">
                                        {
                                            item.filter ? item.filter.name : ''
                                        }
                                    </div>
                                    <div className="qty">
                                        <Button color="dark" size="sm" outline><Icon.Minus width="10px" onClick={() => decOne(item.id, item.filter)} /></Button>
                                        <span>{item.qty}</span>
                                        <Button color="dark" size="sm" outline onClick={() => addOne(item.id, item.filter)}><Icon.Plus width="10px" /></Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        ));

        return list;
    },
    Summary: (props) => {
        const history = useHistory();

        function goNext() {
            history.replace({ pathname: '/cart/shipping' });
        }

        return (
            <>
                <h3><span>O</span>rder Summary</h3>
                <div className="mb-3 cart-common order-summary">
                    <div className="total">Total: <span>₹{props.summary.without_offer}</span></div>
                    <div className="discount">Discount:
                        {
                            props.summary.without_offer != props.summary.sum ? <span> -₹{props.summary.without_offer - props.summary.sum}</span> : <span> ₹0</span>
                        }

                    </div>
                    <div className="line"><div className="lite"></div></div>
                    <div className="total-final">Order Total: <span>₹{props.summary.sum}</span></div>
                </div>
                <Button block color="success" className="anim" onClick={goNext}>Continue<Icon.ArrowRight /></Button>
            </>
        )
    }
};
export default Cart;