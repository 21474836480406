import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'tabler-react';
import Axios from 'axios';
import api from '../utils/Api';
import { useStore } from '../utils/Store';

const OrderPlaced = {
    Index: () => {
        const [store, setStore] = useStore();
        const [orderState, setOrder] = useState(0);
        useEffect(() => {
            setOrder(0);
            Axios.post(api.ORDER_SUBMIT, { items: store.cart, mode: store.paymentMode, delivery: store.deliveryMethod, address: store.deliveryAddress },
                {
                    headers: {
                        'Authorization': store.user
                    }
                })
                .then(res => {
                    if (res.data.success) {
                        setStore({ ...store, cart: [] });
                        setOrder(1);
                    } else {
                        setOrder(2);
                    }
                }, err => {
                    setOrder(2);
                })
        }, []);

        const view = orderState === 1 ? <OrderPlaced.Success /> : <OrderPlaced.Failed />;
        return orderState === 0 ? <OrderPlaced.Processing /> : view;
    },
    Processing: () => {
        return (<div className="order-placed-container py-5">
            <div className="icon-pop my-5">
                <div className="icon-busy-block my-5">
                    <div className="icon-outer"><Icon.Maximize /></div>
                    <div className="icon-anim"><Icon.Package /></div>
                </div>
            </div>
            <h1><span>P</span>rocessing...</h1>
        </div>)
    },
    Success: () => {
        return <div className="order-placed-container py-5">
            <div className="icon-pop my-5">
                <div className="icon-success-block my-5">
                    <div className="icon-anim"><Icon.Check /></div>
                </div>
            </div>
            <h1><span>O</span>rder received!</h1>
            <div className="py-3">
                <Link to="/"><Button outline color="success">Shop more</Button></Link>
            </div>
        </div>;
    },
    Failed: () => {
        return <div className="order-placed-container py-5">
            <div className="icon-pop my-5">
                <div className="icon-danger-block my-5">
                    <div className="icon-anim"><Icon.X /></div>
                </div>
            </div>
            <h1><span className="red">S</span>omething went wrong!</h1>
            <div className="py-3">
                <Link to="/cart/pay"><Button outline color="success">Try again</Button></Link>
            </div>
        </div>;
    }
}


export default OrderPlaced;