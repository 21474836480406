import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import Category from './Category';
import Product from './Product';


const routesList = [{
    path: "/category/:catID",
    exact: true,
    component: Category.Index
}, {
    path: "/product/:prdID",
    exact: true,
    component: Product.Index
}];


const ShopRoutes = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            {routesList.map((route, index) => (
                <Route
                    key={index}
                    path={match.path + route.path}
                    exact={route.exact}
                    children={<route.component />}
                />

            ))}
        </Switch>
    );
}

export default ShopRoutes;