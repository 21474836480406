import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'tabler-react';
import { useStore } from '../utils/Store';
import './User.scss';
import { useHistory, useLocation } from 'react-router-dom';
import userIMG from '../assets/placeholders/verify-user.svg';
import Axios from 'axios';
import api from '../utils/Api';

const SignIn = {
    Index: () => {
        const [store, setStore] = useStore();
        const [showOTP, setLayout] = useState(false);
        const [userMob, setMob] = useState();

        function generateOTP() {
            Axios.post(api.GENERATE_OTP, { user: userMob })
                .then(res => {
                    if (res.data.success) {
                        setLayout(true);
                    }
                }, err => {

                });
        }

        const mobControlChange = (data) => {
            setMob(data.target.value);
        }

        const view = (
            <div className="signin-container pt-5">
                <h1>Verify your contact</h1>
                <Card>
                    <Card.Body>
                        <Form.Group>
                            <Form.Input icon="user" placeholder="Enter your 10 digit mobile number" maxLength="10" onChange={mobControlChange.bind(this)} />
                        </Form.Group>
                        <Button block color="success" onClick={generateOTP}>Verify</Button>
                    </Card.Body>
                </Card>
            </div>

        )
        return (<>
            {showOTP ? <SignIn.VerifyOTP user_id={userMob} /> : view}
            <div className="page-placeholder pt-5">
                <img src={userIMG} />
            </div>
        </>
        );
    },
    VerifyOTP: (props) => {
        let history = useHistory();
        let location = useLocation();
        const [store, setStore] = useStore();
        const [otp, setOtp] = useState(0);

        let { from } = location.state || { from: { pathname: "/" } };

        useEffect(() => {
            if (store.user) {
                history.replace(from);
            }
        }, [store.user]);

        const login = () => {

            Axios.post(api.VALIDATE_OTP, { otp: otp, user: props.user_id })
                .then(res => {
                    if (res.data.success) {
                        setStore({ ...store, user: res.data.data.user });
                    }
                }, err => {

                });


        }

        const otpControl = (event) => {
            setOtp(event.target.value);
        }

        const view = (
            <div className="signin-container pt-5">
                <h1>Verify OTP</h1>
                <Card>
                    <Card.Body>
                        <Form.Group>
                            <Form.Input icon="user" type="password" placeholder="Enter the OTP recieved" maxLength="4" onChange={otpControl.bind(this)} />
                        </Form.Group>
                        <Button block color="success" onClick={login}>Validate</Button>
                    </Card.Body>
                </Card>
            </div>

        )

        return view;

    }
}

export default SignIn;