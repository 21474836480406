import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge, Card, Grid } from 'tabler-react';
import { useStore } from '../utils/Store';

const ItemsGrid = (props) => {
    const [store, setStore] = useStore();

    function checkItemInCart(id, filter) {
        const found = store.cart.filter(item => item.id === id && item.filter === filter);
        return found.length;
    }

    function addItem(id) {

        if (checkItemInCart(id, null)) {
            const cartData = store.cart.map(item => {
                if (item.id === id) { item.qty++; }
                return item;
            });

            setStore({ ...store, cart: cartData });

        } else {
            store.cart.push({ id, qty: 1, filter: null });
            setStore({ ...store });
        }


    }

    const item = props.items.map((item, key) =>
        <Grid.Col width="6" sm="4" md="3" lg="2" className="mb-4" key={item.id}>
            <Link to={`${'/shop/product/' + item.id}`}>
                <Card>
                    <Card.Body className={'common product-item ' + (item.stock === 0 ? 'no-stock' : '')} key={key}>
                        <div className="thumb">
                            <img src={item.thumb} alt={item.name} />
                            <label className="unavailable">Out of stock</label>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="details">
                            <div className="title">
                                {item.name.slice(0, 34)}{item.name.length > 34 ? '...' : ''}
                            </div>
                            <div>{item.native}</div>

                            {
                                item.offer != 0 ?
                                    (<div className="p-wrap">
                                        <div className="price">₹{item.price}<span className="off">₹{item.price_real}</span></div>
                                        <div className="off-label">-{item.offer}</div></div>)
                                    : (<div className="price">₹{item.price}</div>)
                            }
                        </div>
                    </Card.Footer>
                </Card>
            </Link>
        </Grid.Col>
    );

    return (
        <div className="common items-grid">
            {
                props.title ? (<div className="header">
                    <label><h3><span>{props.title[0]}</span>{props.title.slice(1)}</h3></label>
                </div>) : ''
            }
            <Grid.Row className="list-wrapper">{item}</Grid.Row>
        </div>
    )
}

export default ItemsGrid;