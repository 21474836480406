import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import api from '../utils/Api';
import { useParams, Link, useRouteMatch, useLocation } from 'react-router-dom';
import ItemsGrid from '../Common/ItemsGrid';
import * as Icon from 'react-feather';
import './Shop.scss';
import { Button, Dropdown, Grid } from 'tabler-react';
import Loader from '../Common/Loader';

const Category = {
    Index: () => {
        let { catID } = useParams();
        const [categoryData, loadCategory] = useState({ total: 0, items: [], sections: [] })
        useEffect(() => {
            loadCategory({ total: 0, sections: [], items: [], });
            Axios.get(api.SHOP_CATEGORY + '/' + catID + '/products/all')
                .then(res => {
                    if (res.data.success) {
                        loadCategory(res.data.data);
                    } else {
                        loadCategory({ total: 0, sections: [], items: [], });
                    }
                }, err => {

                });
        }, [catID]);

        const view = (
            <div className="shop-cat-container">
                <div className="line pb-5"><div className="lite"></div></div>
                {
                    categoryData.name ? <h1><span>{categoryData.name[0]}</span>{categoryData.name.slice(1)}</h1> : ''
                }
                {
                    categoryData.items ? <Category.AllProducts category={categoryData} /> : <Category.Section category={categoryData} />
                }


            </div>
        );

        return <div>{categoryData.id ? view : <Loader />}</div>
    },
    Section: (props) => {
        return (<>
            <div className="counter">
                <div><Icon.CornerDownRight /><b>{props.category.total} products</b></div>
            </div>
            {
                props.category.sections.map(category => (
                    <div className="row-section mb-5" key={category.id}>
                        <ItemsGrid items={category.items} title={category.name} />
                        {
                            category.total > category.items.length ?
                                <Grid.Row className="justify-content-center">
                                    <Grid.Col width="12" md="4">
                                        <Link to={'/shop/category/' + category.id}><Button block outline color="secondary">+ {category.total - category.items.length} more products</Button></Link>
                                    </Grid.Col>
                                </Grid.Row> : ''
                        }
                    </div>
                ))
            }
        </>)

    },
    AllProducts: (props) => {
        let { pathname } = useLocation();
        return (<>
            <div className="counter">
                <div><Icon.CornerDownRight /><b>{props.category.total} products</b></div>
                <Button.Dropdown value="Sort">
                    <Dropdown.Item><Link to={pathname + '?sort=price'}>Low price</Link></Dropdown.Item>
                    <Dropdown.Item><Link to={pathname + '?sort=rating'}>Rating</Link></Dropdown.Item>
                </Button.Dropdown>
            </div>
            <div className="row-section mb-5">
                <ItemsGrid items={props.category.items} />
            </div>
        </>)
    }
};


export default Category;