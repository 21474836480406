import React from 'react';
import {
    Switch,
    useRouteMatch
} from "react-router-dom";

import Cart from './Cart';
import Shipping from './Shipping';
import { useStore } from '../utils/Store';
import Pay from './Pay';
import { RouterPath } from '../Router';
import OrderPlaced from './OrderPlaced';

const View = () => {
    const [store] = useStore();

    const header = (<div className="cart-container">
        <div className="line pb-5"><div className="lite"></div></div>
        <div className="header pb-5">
            <h1>
                {store.cartStep === 1 ? (<><span>M</span>y shopping cart</>) : ''}
                {store.cartStep === 2 ? (<><span>C</span>hoose delivery address</>) : ''}
                {store.cartStep === 3 ? (<><span>D</span>elivery & Payment</>) : ''}
            </h1>
            <div className="steps col-12 col-sm-4 justify-content-between pt-4 pt-sm-0">
                <div className="line">
                    <div className="lite"></div>
                </div>
                <div className={`bubble b1 ${store.cartStep === 1 ? 'active' : ''}`} >1</div>
                <div className={`bubble b2 ${store.cartStep === 2 ? 'active' : ''}`}>2</div>
                <div className={`bubble b3 ${store.cartStep === 3 ? 'active' : ''}`}>3</div>
            </div>
        </div>
    </div>
    );


    return store.cart.length > 0 ? header : (<div className="cart-container">
        <div className="line pb-5"><div className="lite"></div></div></div>);
}


const CartRoutes = () => {
    let match = useRouteMatch();

    const routesList = [
        {
            path: '',
            exact: true,
            component: Cart.Index
        },
        {
            path: "/shipping",
            exact: true,
            component: Shipping.Index,
            authGuard: true
        }, {
            path: '/pay',
            exact: true,
            component: Pay.Index,
            authGuard: true
        }, {
            path: '/success',
            exact: true,
            component: OrderPlaced.Index,
            authGuard: true,
            hideStep: true
        }];


    return (
        <Switch>
            {routesList.map((route, index) => (
                <RouterPath
                    key={index}
                    path={match.path + route.path}
                    exact={route.exact}
                    authGuard={route.authGuard}
                >
                    {
                        route.hideStep ? '' : <View />
                    }
                    <route.component />
                </RouterPath>
            ))}
        </Switch>
    );
}

export default CartRoutes;