import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'tabler-react';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';

const ItemsRotator = (props) => {
    const [store, setStore] = useStore();

    function checkItemInCart(id, filter) {
        const found = store.cart.filter(item => item.id === id && item.filter === filter);
        return found.length;
    }

    function addItem(id) {

        if (checkItemInCart(id, null)) {
            const cartData = store.cart.map(item => {
                if (item.id === id) { item.qty++; }
                return item;
            });
            setStore({ ...store, cart: cartData });
        } else {
            store.cart.push({ id, qty: 1, filter: null });
            setStore({ ...store });
        }
    }

    const itemsList = props.items.map((item, key) =>
        <div className={'common product-item ' + (item.stock === 0 ? 'no-stock' : '')} key={key}>
            <div className="thumb">
                <Link to={`${'/shop/product/' + item.id}`}><img src={item.thumb} alt={item.name} /></Link>
                <label className="unavailable">Out of stock</label>
            </div>
            <div className="details">
                <div className="title">
                    <Link to={`${'/shop/product/' + item.id}`}>{item.name} </Link>
                </div>
                <div>{item.native}</div>

                {
                    item.offer != 0 ?
                        (<div className="p-wrap"><div className="price">₹{item.price}<span className="off">₹{item.price_real}</span></div>
                            <Badge color="success">{item.offer}</Badge></div>)
                        : (<div className="price">₹{item.price}</div>)
                }
            </div>
            {
                item.stock > 0 ? <div className={'button ' + (checkItemInCart(item.id) ? 'in-cart' : '')}>
                    <Button pill size="sm" color="success" onClick={() => addItem(item.id)}>ADD</Button>
                    <div className="tick-success"><Icon.Check width="18px" /></div>
                </div> : ''
            }

        </div>
    );

    return props.items.length > 0 ? (
        <div className="common items-rotator">
            <div className="header">
                {props.title ? <h1 className="title"><span>{props.title[0]}</span>{props.title.slice(1)}</h1> : ''}
                {props.subtitle ? <h3>{props.subtitle}</h3> : ''}
            </div>
            <div className="scroll-view">
                <div className="list-wrapper" style={{ width: 'calc(180px * ' + props.items.length + ')' }}>{itemsList}</div>
            </div>
        </div>
    ) : '';
}

export default ItemsRotator;