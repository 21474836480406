import React, { useEffect, useState } from 'react';
import { Container } from 'tabler-react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { useStore } from '../utils/Store';

import './Page.scss';

import brandLogo from '../assets/brand-logo.jpg';
import Axios from 'axios';
import api from '../utils/Api';

const Page = {
    Header: () => {
        const [store, setStore] = useStore();

        useEffect(() => {
            Axios.get(api.ALL_CATEGORY)
                .then(res => {
                    if (res.data.success) {
                        setStore({ ...store, categoryList: res.data.data });
                    }
                }, err => {

                });
        }, []);

        useEffect(() => {
            if (store.cart.length > 0) {
                document.getElementById('cart-counter').classList.add('anim');
                setTimeout(() => {
                    document.getElementById('cart-counter').classList.remove('anim');
                }, 1000);
            }
        }, [store.cart.length, store.cart]);

        function openMenu() {
            setStore({ ...store, showMobileMenu: true });
        }

        function hideMenu() {
            setStore({ ...store, showMobileMenu: false });
        }

        const OffersPanel = (
            <div>
                <div className="header-offers">
                    <Container>
                        <div className="message">Use coupon COVID19 to avail 10% discount on your first order</div>
                    </Container>
                </div>
            </div>);

        const MenuBar = (
            <Container className="main-menu">
                <div className="menu-wrapper py-1 p-sm-4">
                    <div className="explore-trigger" onClick={openMenu}><Icon.Menu /></div>
                    <div className="logo"><Link to="/"><img src={brandLogo} alt="Koonathil" /></Link></div>
                    <div className="menu-bar">
                        <ul>
                            <li>
                                <div className="hover-menu">
                                    <div className="item">
                                        <div className="ico">
                                            <Icon.Tag />
                                        </div>
                                        <div className="title">Store</div>
                                    </div>
                                    <div className="child-menu">
                                        <div className="box-green sub-menu">
                                            {
                                                store.categoryList.map((item, key) => <Link to={`/shop/category/${item.id}`} key={item.id}><div>{item.name}</div></Link>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="info-bar py-3 px-sm-4">
                    <div className="item search"><Icon.Search /></div>
                    <div className="item support"><Icon.PhoneCall /> +91 8078857998</div>
                    <div className="item">
                        <Link to="/cart" className="cart-ico"><Icon.ShoppingCart /> <span id="cart-counter" className="cart-count">{store.cart.length}</span></Link>
                    </div>
                </div>
            </Container>
        );

        const mobileMenu = (
            <div className="mobile-menu">
                <div className="overlay" />
                <Icon.X className="close-menu" onClick={hideMenu} width="30px;" />
                <div className="list">{
                    store.categoryList.map((item, key) => <Link to={`/shop/category/${item.id}`} key={item.id}><div>{item.name}</div></Link>)
                }
                </div>
            </div>
        );


        const DOM = (
            <>
                {OffersPanel}
                {MenuBar}
                {
                    store.showMobileMenu ? mobileMenu : ''
                }
            </>
        );


        return store.categoryList.length ? DOM : (
            <div className="website-loader">
                <img src={brandLogo} alt="Loading...." />
            </div>
        );
    },
    footer: () => {
        const DOM = (
            <div className="footer-wrapper mt-5">
                <Container>
                    <div className="credits">
                        Made with <div className="heart-wrapper"><Icon.Heart fill="#000" width="15" className="heart" /></div> by MWA
                    </div>
                </Container>
            </div>
        );

        return DOM;
    }
};

export default Page;