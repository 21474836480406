import settings from '../app.config';

const api = {
    ALL_CATEGORY: settings.server + '/category/all',
    HOME_DATA: settings.server + '/home/data',
    CART_GUEST: settings.server + '/cart/guest',
    CART_USER: settings.server + '/cart/user',
    SHOP_CATEGORY: settings.server + '/category',
    PRODUCT: settings.server + '/product',
    ORDER_SUBMIT: settings.server + '/order/submit',
    GENERATE_OTP: settings.server + '/user/login',
    VALIDATE_OTP: settings.server + '/user/validate',
    SHIPPING_ALL: settings.server + '/user/address',
    SHIPPING_ADD: settings.server + '/user/address/add'
}

export default api;