import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import api from '../utils/Api';
import { useParams } from 'react-router-dom';
import ItemsRotator from '../Common/ItemsRotator';
import { Grid, Badge, Button } from 'tabler-react';
import './Shop.scss';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';
import Loader from '../Common/Loader';

const Product = {
    Index: () => {
        let { prdID } = useParams();
        const [store, setStore] = useStore();
        const [productData, setProduct] = useState({ success: false });
        const [filterData, setFilter] = useState({ id: null, price: 0, offer: 0 });

        useEffect(() => {
            setProduct({ success: false });
            Axios.get(api.PRODUCT + '/' + prdID)
                .then(res => {
                    if (res.data.success) {
                        setProduct({ success: true, ...res.data.data });

                        if (res.data.data.filters.length > 0) {
                            const defaultFilter = res.data.data.filters[0];
                            if (defaultFilter.data.length > 0) {
                                setFilter(defaultFilter.data[0]);
                                setProduct({ success: true, ...res.data.data, price: defaultFilter.data[0].price, offer: defaultFilter.data[0].offer, price_real: defaultFilter.data[0].price_real });
                            }
                        }

                    }
                }, err => {

                });
        }, [prdID]);

        function checkItemInCart(id) {
            const found = store.cart.filter(item => item.id === id && item.filter === filterData.id);
            return found.length;
        }

        function addToCart() {
            const id = productData.id;
            if (checkItemInCart(id)) {
                const cartData = store.cart.map(item => {
                    if (item.id === id && item.filter === filterData.id) {
                        item.qty++;
                        item.filter = filterData.id;
                    }
                    return item;
                });

                setStore({ ...store, cart: cartData });

            } else {
                store.cart.push({ id, qty: 1, filter: filterData.id });
                setStore({ ...store });
            }
        }

        function selectFilter(data) {
            setFilter(data);
            setProduct({ ...productData, price: data.price, offer: data.offer, price_real: data.price_real });
        }

        const view = (
            <Grid.Row className="prd-details">
                <Grid.Col width="12" md="6" className="align-items-center mb-5">
                    <div className="prd-img">
                        <img src={productData.img} alt={productData.name} />
                    </div>
                </Grid.Col>
                <Grid.Col width="12" md="6" className="mb-5">
                    {
                        productData.offer && productData.offer !== '0' ? <Badge color="success" className="offer">{productData.offer}</Badge> : ''
                    }

                    <div className="title">{productData.name}</div>
                    <div className="native">{productData.native}</div>

                    {
                        productData.offer && productData.offer !== '0' ? (<>
                            <div className="price old">MRP: <span>₹{productData.price_real}</span></div>
                            <div className="discount">Discounted price: <span>₹{productData.price}</span></div>
                        </>) : (<div className="price">MRP: <span>₹{productData.price}</span></div>)
                    }

                    <div className="line py-3"><div className="lite"></div></div>
                    {
                        productData.filters ? productData.filters.map((filter, key) => (
                            <div className="wt pb-4" key={key}>
                                <div className="label">{filter.name}:</div>
                                <Button.List>
                                    {
                                        filter.data.map((item, ikey) =>
                                            <Button color="primary" key={ikey} outline={filterData.id === item.id ? false : true} pill className="mr-2 mb-2" onClick={() => selectFilter(item)}>{item.name}</Button>
                                        )
                                    }
                                </Button.List>
                            </div>)
                        ) : ''
                    }

                    <Grid.Row>
                        <Grid.Col width="12" md="6" lg="5" className={'cart-btn ' + (checkItemInCart(productData.id) ? 'in-cart' : '')}>
                            <Button block color="success" onClick={addToCart}>Add To Cart
                            <div className="tick-success"><Icon.Check /></div></Button>

                        </Grid.Col>
                    </Grid.Row>


                </Grid.Col>
            </Grid.Row>
        )

        const view_set = (
            <>
                {view}
                <Product.Related data={productData.related} />
                <Product.Popular data={productData.top_selling} />
            </>
        )

        return (
            <div className="product-container">
                <div className="line pb-5"><div className="lite"></div></div>
                {
                    productData.success ? view_set : <Loader />
                }

            </div>
        );
    },
    Related: (props) => {
        return props.data.data ? (<>
            <div className="line pb-5"><div className="lite"></div></div><ItemsRotator items={props.data.data} title='More like this' /></>) : '';

    },
    Popular: (props) => {
        return props.data.data ? <>
            <div className="line pb-5"><div className="lite"></div></div><ItemsRotator items={props.data.data} title='Most popular' /></> : '';
    }

}


export default Product;