import React, { useEffect, useState } from 'react';
import { useStore } from '../utils/Store';
import { Card, Grid, Stamp, Button, Form } from 'tabler-react';
import * as Icon from 'react-feather';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import api from '../utils/Api';

const Shipping = {
    Index: () => {
        const [store, setStore] = useStore();
        const [address, setAddress] = useState([]);
        const [showAddNew, setAddNew] = useState(false);
        const [form, setForm] = useState({ name: '', data: '' });
        const history = useHistory();

        useEffect(() => {
            if (store.user) {
                Axios.get(api.SHIPPING_ALL, {
                    headers: {
                        'Authorization': store.user
                    }
                }).then(res => {
                    if (res.data.success) {
                        setAddress(res.data.data);
                        res.data.data.map(item => {
                            if (item.active === "1") {
                                setStore({ ...store, cartStep: 2, deliveryAddress: item.id })
                            }
                        });
                    } else {
                        toggleAddress()
                    }
                }, err => {

                });
                setStore({ ...store, cartStep: 2, deliveryAddress: null });
            }
        }, [store.user]);

        function redirect(pathname, force = false) {
            if (showAddNew && !force) {
                processAddress();
            } else {
                history.replace({ pathname });
            }
        }

        function toggleAddress() {
            setAddNew(!showAddNew);
        }

        function processAddress() {
            Axios.post(api.SHIPPING_ADD, { ...form }, {
                headers: {
                    'Authorization': store.user
                }
            }).then(res => {
                if (res.data.success) {
                    setActiveAddress(res.data.data.id);
                    history.replace({ pathname: '/cart/pay' });
                }
            }, err => {

            })
        }

        function setActiveAddress(id) {
            setStore({ ...store, deliveryAddress: id })
        }

        const controlChange = (type, val) => {
            switch (type) {
                case 'name': setForm({ ...form, name: val }); break;
                case 'data': setForm({ ...form, data: val }); break;
            }
        }

        const list = (
            <Grid.Row cards className="shipping-container">
                {
                    address.map(item => <Grid.Col width="12" sm="4" className={'item ' + (store.deliveryAddress === item.id ? 'active' : '')} key={item.id}>
                        <Card className="p-3 mb-3" onClick={() => setActiveAddress(item.id)}>
                            <div>
                                <div><b>{item.name}</b></div>
                                <div>{item.data}</div>
                            </div>
                            <div className="active-stamp">
                                <Stamp size="xs" color="success" className="mr-1">
                                    <Icon.Check width="15px" />
                                </Stamp> Default
                            </div>
                        </Card>
                    </Grid.Col>
                    )
                }

                <Grid.Col width="12" sm="4" className="item new">
                    <Card className="p-3 mb-3" onClick={toggleAddress}>
                        <div className="icon">
                            <Icon.Plus width="50px" />
                        </div>
                        <div>
                            Add New
                        </div>
                    </Card>
                </Grid.Col>

            </Grid.Row>
        );

        const addAddressView = (
            <Grid.Row>
                <Grid.Col sm="12" md="6">
                    <h3><span>Add</span> new address</h3>
                    <Form.Group label="Full Name">
                        <Form.Input icon="user" type="text" placeholder="Enter the name of reciever" onChange={(event) => controlChange('name', event.target.value)} />
                    </Form.Group>
                    <Form.Group label="Address">
                        <Form.Textarea onChange={(event) => controlChange('data', event.target.value)} />
                    </Form.Group>
                </Grid.Col>
            </Grid.Row>
        );

        const view = (
            <div>
                {showAddNew ? addAddressView : list}
                <Grid.Row className="pt-5 justify-content-sm-between">
                    <Grid.Col width="12" sm="3" className="mb-3"><Button block outline color="secondary" className="anim" onClick={() => redirect('/cart', true)}> <Icon.ArrowLeft />Modify your Order</Button></Grid.Col>
                    <Grid.Col width="12" sm="5" md="4" lg="3" className="mb-3"><Button block color="success" className="anim" onClick={() => redirect('/cart/pay')}>Continue Checkout<Icon.ArrowRight /></Button></Grid.Col>
                </Grid.Row>
            </div>
        )

        return view;
    }
}

export default Shipping