import React, { useEffect } from 'react';
import {
    Switch,
    useRouteMatch
} from "react-router-dom";
import { RouterPath } from '../Router';
import SignIn from './SignIn';


const routesList = [{
    path: "/verify",
    exact: true,
    component: SignIn.Index
}];


const UserRoutes = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            {routesList.map((route, index) => (
                <RouterPath
                    key={index}
                    path={match.path + route.path}
                    exact={route.exact}
                    children={<route.component />}
                    authGuard={route.authGuard}
                />

            ))}
        </Switch>
    );
}

export default UserRoutes;