import React from 'react';
import './App.scss';
import './Common/Common.scss';

import Page from './Layout/Page';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { Container } from 'tabler-react';
import { Routes } from './Router';


function App() {
  const view = (
    <Router>
      <div className="site-wrapper">
        <div>
          <Page.Header />
          <Container className="px-sm-3">
            <Routes />
          </Container>
        </div>
        <Page.footer />
      </div>
    </Router>
  );

  return view;
}
export default App;
